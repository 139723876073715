export const project1 = [
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(1).png",
    width: 4,
    height: 3
  },
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(2).png",
    width: 4,
    height: 3
  },
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(3).png",
    width: 4,
    height: 3
  },
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(4).png",
    width: 4,
    height: 3
  },
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(5).png",
    width: 4,
    height: 3
  },
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(6).png",
    width: 4,
    height: 3
  },
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(7).png",
    width: 4,
    height: 3
  },
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(8).png",
    width: 4,
    height: 3
  },
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(9).png",
    width: 4,
    height: 3
  },
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(10).png",
    width: 4,
    height: 3
  }
  ,
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(11).png",
    width: 4,
    height: 3
  },
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(12).png",
    width: 4,
    height: 3
  }
  ,
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(13).png",
    width: 4,
    height: 3
  },
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(14).png",
    width: 4,
    height: 3
  }
  ,
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(15).png",
    width: 4,
    height: 3
  },
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(16).png",
    width: 4,
    height: 3
  }
  ,
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(17).png",
    width: 4,
    height: 3
  },
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(18).png",
    width: 4,
    height: 3
  }
  ,
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project1/project1_%20(19).png",
    width: 4,
    height: 3
  }
];

export const project2 = [
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project2/picture1.png",
    width: 3,
    height: 4
  }, {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project2/picture2.png",
    width: 3,
    height: 4
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project2/picture3.png",
    width: 3,
    height: 4
  }, {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project2/picture4.jpeg",
    width: 3,
    height: 4
  }, {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project2/picture5.png",
    width: 3,
    height: 4
  }
]


export const project3 = [
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project3/pic%20(1).png",
    width: 4,
    height: 3
  }, {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project3/pic%20(2).jpeg",
    width: 3,
    height: 4
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project3/pic%20(3).jpeg",
    width: 3,
    height: 4
  }, {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project3/pic%20(4).jpeg",
    width: 3,
    height: 4
  }, {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project3/pic%20(1).jpeg",
    width: 3,
    height: 4
  }
]


export const project4 = [
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project4/trend1.jpg",
    width: 3,
    height: 4
  }, {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project4/trend2.jpg",
    width: 3,
    height: 4
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project4/trend3.jpg",
    width: 3,
    height: 4
  }
]

export const project5 = [
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project5/pic%20(1).png",
    width: 4,
    height: 3
  }, {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project5/pic%20(2).png",
    width: 4,
    height: 3
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project5/pic%20(3).png",
    width: 4,
    height: 3
  }, {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project5/pic%20(4).png",
    width: 4,
    height: 3
  }]

export const project6 = [
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project6/pic%20(1).png",
    width: 4,
    height: 3
  }, {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project6/pic%20(2).png",
    width: 4,
    height: 3
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project6/pic%20(3).png",
    width: 4,
    height: 3
  }, {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project6/pic%20(4).jpeg",
    width: 4,
    height: 3
  }]

export const project7 = [
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project7/pic%20(1).png",
    width: 4,
    height: 3
  }, {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project7/pic%20(2).png",
    width: 4,
    height: 3
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/project7/pic%20(3).png",
    width: 4,
    height: 3
  }]

export const gallery = [
  {
    src: "https://claudiaromero.work/wp-content/uploads/assets/gallery/pic1.jpg",
    width: 4,
    height: 3
  }, {
    src: "https://claudiaromero.work/wp-content/uploads/assets/gallery/pic2.jpg",
    width: 2.5,
    height: 4
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/gallery/pic3.jpg",
    width: 2.5,
    height: 4
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/gallery/pic4.jpg",
    width: 3,
    height: 4
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/gallery/pic5.jpg",
    width: 2.5,
    height: 4
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/gallery/pic6.jpg",
    width: 2.5,
    height: 4
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/gallery/pic7.jpg",
    width: 4,
    height: 3
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/gallery/pic8.jpg",
    width: 5,
    height: 3
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/gallery/pic9.jpg",
    width: 5,
    height: 3
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/gallery/pic10.jpg",
    width: 5,
    height: 3
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/gallery/pic11.jpg",
    width: 5,
    height: 3
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/gallery/pic12.jpg",
    width: 2.5,
    height: 4
  }
  , {
    src: "https://claudiaromero.work/wp-content/uploads/assets/gallery/pic13.jpg",
    width: 5,
    height: 3
  }]