import React from 'react';
import './App.css';

import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Pages/Layout';
import { Home } from './Pages/Home';
import {Gallery} from './Pages/Gallery';
import { About } from './Pages/About';



initializeIcons();

export const App: React.FunctionComponent = () => {




  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="about" element={<About/>} />
            
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};
