import React from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { project1, project2, project3, project4, project5, project6, project7 } from "../photo";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { ProjectGallery } from '../ProjectGallery';
import { Separator } from '@fluentui/react/lib/Separator';


initializeIcons();

export const Home: React.FunctionComponent = () => {




    return (
        <div id="mainView">
            <div style={{ display: 'inline-flex', padding: '30px' }}>
                <div style={{ width: '20%', display: 'block' }}>
                    <Text variant={'xLarge'}>Mock-up Design Project inspired by the avant-garde ﬁlm “Three Women.”</Text>
                    <Separator />
                    <Text variant={'mediumPlus'}>I was deeply inspired by various elements in this ﬁlm, including its surreal narrative, captivating color palette, and the stunning artwork of muralist Bodhi Wind. I aimed to infuse this imaginative collection with a streetwear vibe, utilizing primarily knits, nylon, ribbing, and cotton voile materials. This project was an exploration of various fabric treatments and the creation of mock-up details for the eventual production of the line. My design process was a fusion of techniques, including the use of ready-made garments, cutting and draping fabric, hand dyeing, painting, sketching, stamping, and distressing to bring my vision to life.</Text>
                </div>
                <div style={{ width: '80%' }}><ProjectGallery photos={project1} /></div>
            </div><Separator />
            <div style={{ display: 'inline-flex', padding: '30px' }}>

                <div style={{ width: '80%' }}><ProjectGallery photos={project2} /></div>
                <div style={{ width: '20%', display: 'block' }}>
                    <Text variant={'xLarge'}>Oceanic Pleated Dress Sewing Project</Text>
                    <Separator />
                    <Text variant={'mediumPlus'}>Inspired by the magniﬁcent ocean, I discovered numerous outstanding works of art that showcase the sea and its powerful waves. This elegant dress, along with its various layers including a cardigan, and high-collared top, was crafted with care through a combination of hand and machine sewing. The fabric was hand-dyed in a beautiful sea foam green hue, and then sent for professional pleating. The pattern was meticulously ﬂat-drafted and perfected using Tuka-Tech digital technology. Made from 100% poly crinkle chiﬀon and lined with soft cotton voile, this dress has been treated with starch to give it a ﬁrm and structured appearance.</Text>
                </div>
            </div><Separator />
            <div style={{ display: 'inline-flex', padding: '30px' }}>
                <div style={{ width: '20%', display: 'block' }}>
                    <Text variant={'xLarge'}>Athletic Wear - Windbreaker Sewing Project</Text>
                    <Separator />
                    <Text variant={'mediumPlus'}>The composition of this fashionable ensemble consists of a jacket, pants, and shorts made from high-quality nylon and adorned with gleaming satin cord and a custom zipper for added style and functionality. The top is crafted from 100% hand-dyed wool, featuring a striking silkscreen design for a truly one-of-a-kind look.</Text>
                </div>
                <div style={{ width: '80%' }}><ProjectGallery photos={project3} /></div>
            </div><Separator />
            <div style={{ display: 'inline-flex', padding: '30px' }}>

                <div style={{ width: '80%' }}><ProjectGallery photos={project4} /></div>
                <div style={{ width: '20%', display: 'block' }}>
                    <Text variant={'xLarge'}>Trend Report Project - “Undercover Freestyle”</Text>
                    <Separator />
                    <Text variant={'mediumPlus'}>The 90's skateboarding culture was all about being relaxed and cool. This era was defined by a laid-back, rebellious spirit and a love for the freedom of the skateboarding lifestyle. Whether you were shredding the streets, perfecting your tricks in the park, or simply hanging out with friends, the 90's skateboarding culture was all about being yourself and having a good time. With a focus on comfort, functionality, and a sense of nonchalant cool, this era continues to influence skateboarding fashion and style to this day. So embrace the relaxed and cool vibe, and let your love for skateboarding shine through!</Text>
                </div>
            </div><Separator />
            <div style={{ display: 'inline-flex', padding: '30px' }}>
                <div style={{ width: '20%', display: 'block' }}>
                    <Text variant={'xLarge'}>Digital Systems Analysis Project “Tennis, Anyone?”</Text>
                    <Separator />
                    <Text variant={'mediumPlus'}>Step back in time with this retro tennis apparel, made from a comfortable blend of materials including jersey, double knit spandex, tricot, and ribbing. This combination of fabrics provides a ﬂexible and supportive ﬁt, perfect for any match on the court. Get ready to serve up some style with this vintage-inspired tennis gear.</Text>
                </div>
                <div style={{ width: '80%' }}><ProjectGallery photos={project5} /></div>
            </div><Separator />
            <div style={{ display: 'inline-flex', padding: '30px' }}>

                <div style={{ width: '80%' }}><ProjectGallery photos={project6} /></div>
                <div style={{ width: '20%', display: 'block' }}>
                    <Text variant={'xLarge'}>Children’s Design Project “Baby Robato, Kawaii-Tron-Laser Cats”</Text>
                    <Separator />
                    <Text variant={'mediumPlus'}>This playful children's design project brings together two of kids' favorite things: robots and cute cats! The collection, titled "Baby Robato, Kawaii-Tron-Laser Cats," is a vibrant and fun-ﬁlled assortment of clothing, inspired by the quirky and eclectic world of Japanese street wear fashion. Whether your little one is a fan of robots, cats, or just loves bold and colorful fashion, this collection is sure to bring a smile to their face and keep them comfortable and stylish all day long!</Text>
                </div>
            </div><Separator />
            <div style={{ display: 'inline-flex', padding: '30px' }}>
                <div style={{ width: '20%', display: 'block' }}>
                    <Text variant={'xLarge'}>Costume Design Project: Manufacturing Fashion Garment Production - Los Angeles Trade Technical College</Text>
                    <Separator />
                    <Text variant={'mediumPlus'}>This stunning design takes inspiration from the legendary character Lady Kaede in the classic 1985 Japanese ﬁlm "Ran". Made from luxurious 100% natural silk border print fabric, this piece is elevated with a heavy satin kimono, creating a look of regal elegance. This design pays homage to the iconic character and is sure to turn heads.</Text>
                </div>
                <div style={{ width: '80%' }}><ProjectGallery photos={project7} /></div>
            </div>
        </div>
    );
};



