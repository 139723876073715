import { FontSizes, Text } from "@fluentui/react";
import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (<div>
    <div style={{ marginBottom: '40px' }}>
      <Text style={{ fontSize: FontSizes.size32 }}>Claudia Romero</Text>
      <div style={{ float: 'right' }}>
        <div>

          <Link to="/" style={{padding: '20px', textDecoration: 'none', cursor: 'pointer'}}><Text variant={'mediumPlus'}>Main</Text></Link>

          <Link to="/gallery" style={{padding: '20px', textDecoration: 'none', cursor: 'pointer'}}><Text variant={'mediumPlus'}>Gallery</Text></Link>

          <Link to="/about" style={{padding: '20px', textDecoration: 'none', cursor: 'pointer'}}><Text variant={'mediumPlus'}>About</Text></Link>

        </div>
      </div>

    </div>
    <div >
    <Outlet />
    </div>
    </div>
  )
};

export default Layout;