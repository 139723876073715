import React from 'react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { Text } from '@fluentui/react/lib/Text';
import { Separator } from '@fluentui/react/lib/Separator';
initializeIcons();

export const About: React.FunctionComponent = () => {
    return (
        <div id="mainView" style={{ margin: 'auto', width: '80%' }}>
            <div >

                <Text variant={'xLarge'}>PROFILE</Text>
                <Separator />
                <Text variant={'mediumPlus'}>As a highly passionate and
                    experienced design professional with over a decade of experience in the field,
                    I bring a unique blend of creativity, technical expertise, and project
                    management skills. My versatile background encompasses leadership roles, administration,
                    research, styling, apparel design, and construction. I am a strong collaborator
                    and thrive in team environments, engaging in brainstorming sessions and
                    research to cultivate innovative ideas. My exceptional planning and
                    organizational skills allow me to effectively prioritize my workflow and ensure
                    that projects are delivered on time and to a high standard. With a
                    self-starting attitude, attention to detail, and a drive to produce exceptional
                    work, I am confident in my ability to make a meaningful contribution to any
                    design team. </Text>
                <Separator />
                <Text variant={'xLarge'}>WORK EXPERIENCE</Text>
                <Separator />
                <p ><Text variant={'xLarge'}>SAMPLE COORDINATOR / DESIGN ASSISTANT </Text>
                </p>
                <p >
                    <Text variant={'mediumPlus'}>Karen Kane Inc. | May 2021 - Present </Text>
                </p>
                <p ><Text variant={'mediumPlus'}>As the Sample Coordinator at Karen Kane, Working closely
                    with the production and design departments,I play a crucial role in optimizing
                    the schedules of samples sent out to showrooms as soon as designs are approved,
                    costed, and completed each season. My thorough understanding of the sample
                    management process and strong interpersonal skills enable me to effectively
                    coordinate and communicate with multiple departments, ensuring that samples are
                    delivered on time and to a high standard. </Text>
                </p>
                <Text variant={'mediumPlus'}>My responsibilities encompass
                    ordering, receiving, consolidating, tagging, distributing, and invoicing
                    samples, making sure that each step of the process is carried out with accuracy
                    and efficiency. </Text>

                <Text variant={'mediumPlus'}>Management of check in/check out process of samples
                    according to established guidelines. </Text>

                <p ><Text variant={'mediumPlus'}>Managing sample requests from business partners, sales
                    representatives, and vendors. Responsibilities include processing promo
                    requests and keeping inventory levels ofpromotional items. </Text></p>

                <p><Text variant={'mediumPlus'}>Maintaining inventory of the sample closet, organizing
                    and archiving past samples, sew-bys, import PPs, and
                    TOPs, ensuring that all samples are properly labeled, stored, and easy to
                    access. </Text></p>

                <p ><Text variant={'mediumPlus'}>Collecting and keeping counts of new duplicate samples,
                    updating dup reports, and switching out samples when changes are made. </Text></p>

                <p ><Text variant={'mediumPlus'}>Assisting with pulling lines and styles for sales
                    meetings, photoshoots, and fittings. </Text></p>

                <p ><Text variant={'mediumPlus'}>Assist with photoshoots on and off site; My
                    responsibilities include pinning flats, packing and unpacking samples,
                    arranging groups in order, steaming clothing, assisting with dress changes, and
                    keeping records of the styles photographed. </Text></p>

                <p ><Text variant={'mediumPlus'}>Sorting, and attaching cut-tickets on deliveries ready
                    for production sewing. </Text></p>

                <p ><Text variant={'mediumPlus'}>Assist technical-designer with checking fit on
                    production goods, tryouts, and imports.This could include updates to measurement specifications, grading rules, or
                    construction details. </Text></p>

                <p ><Text variant={'mediumPlus'}>Assist in processing samples for the Factory Outlet's sales; setting up merchandise displays, engaging with
                    customers, and accurately processing transactions at the point of sale.</Text> </p>

                <p ><Text variant={'mediumPlus'}>Offer office support for multiple departments. </Text></p>
                <Separator />
                <Text variant={'xLarge'}>SEAMSTRESS</Text>

                <p ><Text variant={'mediumPlus'}>Freelance |2012 - 2021 </Text></p>

                <p ><Text variant={'mediumPlus'}>With extensive experience in the
                    film and entertainment industry, I have honed my skills as a talented and
                    versatile tailor. Specializing in men's suits and formal wear for red carpet
                    events, I have provided custom tailoring services to a variety of clients and
                    projects. From costuming and fittings to alterations for photoshoots, music
                    videos, commercial advertisements, and other creative projects, I have
                    collaborated directly with both stylists and clients to bring their vision to
                    life. As an independent on-set tailor, I have honed my abilities to work efficiently
                    and effectively under pressure, consistently delivering high-quality results.</Text> </p>
                <Separator />
                
                <Text variant={'xLarge'}>Sisters Vintage Sales </Text>
                <p>
                <Text variant={'mediumPlus'}>Co-Founder | 2013 - 2017 </Text>
</p>
                <p >
                    <Text variant={'mediumPlus'}>As co-owner and manager of a
                        successful second-hand clothing business with my sister, I gained valuable
                        experience in curating and selling unique, fashion-forward styles, shoes, and
                        novelties. Our business was a regular vendor at the renowned Rose Bowl and
                        Melrose Trading Post markets, where we showcased our carefully selected
                        inventory. In addition, we created an online presence through our website and
                        online gallery, which displayed our best finds styled and beautifully
                        photographed. Our newsletter further engaged our customers and kept them
                        updated on our latest offerings. </Text></p>
                <Separator />
                <Text variant={'xLarge'}>RECEPTIONIST / SHOWROOM ASSISTANT </Text>

                <p ><Text variant={'mediumPlus'}>Blanks Plus | May - Oct. 2017 </Text></p>

                <p >
                    <Text variant={'mediumPlus'}>As a member of a wholesale
                        eco-fiber t-shirt company, I made valuable contributions to the sales and
                        purchasing processes. My responsibilities included setting up new wholesale
                        accounts, generating monthly sales invoice reports, maintaining relationships
                        with vendors, scheduling and following up on showroom appointments, and working
                        on the company's website and promotional designs. </Text></p>
                <Separator />
                <Text variant={'xLarge'}>PRODUCTION ASSISTANT (Paid Internship) </Text>

                <p ><Text variant={'mediumPlus'}>J. Augar Designs | June - Nov. 2008 </Text></p>

                <p ><Text variant={'mediumPlus'}>Worked in all areas of production; prepared materials, set
                    hardware, pattern work, cutting and assembly. </Text></p>

                <p ><Text variant={'mediumPlus'}>Operated industrial single
                    needle, overlock, and walking foot sewing machines. </Text></p>

            </div>
        </div>
    );
};



