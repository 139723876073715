import React from 'react';
import { gallery } from "../photo";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { ProjectGallery } from '../ProjectGallery';



initializeIcons();

export const Gallery: React.FunctionComponent = () => {




    return (
        <div id="mainView">
            <div style={{  padding: '30px' }}>
                <div style={{ width: '100%' }}><ProjectGallery photos={gallery} /></div>
            </div>
            
        </div>
    );
};



